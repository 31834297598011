<template>
  <div>
    <a-row class="left">
        <a-col class="title">小规模增值税1%征收率将于2021年12月31结束</a-col>
        <a-col class="date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
        <a-divider class="main"></a-divider>
        <div class="content">
            <br>
            <p>小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收；</p>
            <p>季度45万的免征增值税政策到期时间为2022年12月31日。</p>
            <br/>
            <div class="p"><b>答：《关于延续实施应对疫情部分税费优惠政策的公告》（财政部 税务总局公告2021年第7号）规定：</b></div>
            <p>为进一步支持疫情防控，帮助企业纾困发展，《财政部 税务总局关于支持个体工商户复工复业增值税政策的公告》（财政部 税务总局公告2020年第13号）规定的税收优惠政策，执行期限延长至2021年12月31日。</p>
            <p>也就是说从2021年4月1日起至12月31日，所有增值税小规模纳税人，适用3%征收率的应税销售收入，减按1%征收率征收增值税；适用3%预征率的预缴增值税项目，减按1%预征率预缴增值税。</p>

            <p>目前还没有收到延长该优惠政策的相关文件，但是近期李总理在发展壮大市场主体工作座谈会上指出，当前经济面临新的下行压力，1亿多市场主体是我国经济发展的底气、韧性所在，是稳住经济基本盘的重要基础。要针对市场主体特别是中小微企业、个体工商户的生产经营困难，研究制定新的阶段性、组合式减税降费政策，普惠直达市场主体，继续引导金融机构向实体经济合理让利。从总理的讲话中，可以看出这是一个利好的消息，小规模纳税人增值税3%征收率减为1%的优惠政策大概率会再次延期，如果即使不延期，也会出台其他的优惠政策，来纾解小微企业、个体户的经营困难。</p>
            <p><b>我们在等待官方正式文件之前还是要提前做好两手准备，以免政策到期后，不能继续开具1%发票。</b></p>
        </div>

        <a-row :gutter="20">
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s1')">
                    <div class="align">
                        <p class="to">上一篇</p>
                        <p class="to-title">中再物贸云交易平台上线</p>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s3')">
                    <div class="align">
                        <p class="to">下一篇</p>
                        <p class="to-title">中再物贸云交易平台执行“实名认证”入驻</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-row>

    <a-row class="right">
        <a-col class="date">其他公告</a-col>
        <a-divider class="main"></a-divider>
        
        <a-row class="r-item" v-for="item in list" :key="item.title">
            <a-col class="to-title" @click="$router.push(item.router)">{{item.title}}</a-col>
            <a-col class="r-text">{{item.text}}</a-col>
            <a-col class="r-date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
            <a-divider></a-divider>
        </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {title:'中再物贸云交易平台上线啦！',text:'针对再生资源行业税票乱象问题，中再物贸云通过多年的交易经验与相关部门合作...',router: '/notice-details/s1'},
                {title:'中再物贸云交易平台执行“实名认证”入驻',text:'为确保交易自然人的知悉权，避免身份被他人借用的情况，平台卖家入驻时需...',router: '/notice-details/s3'},
                {title:'关于个体工商户代开发票限额的通知',text:'根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》...',router: '/notice-details/s4'},
                {title:'中再物贸云网依法进行主体登记，履行纳税义务',text:'依据《中华人民共和国电子商务法》, 中再物贸云交易平台依法进行主体登记，履行纳税义务...',router: '/notice-details/s5'},
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.left {
    @media screen and (max-width: 1440px){
        width: 60%;
    }
    display: inline-block;
    vertical-align: top;
    width: 960px;
    margin-right: 42px;
}
.right {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1440px){
        width: calc(40% - 42px);
    }
    width: 352px;
    line-height: 24px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
.date {
    color: #AAAAAA;
    font-size: 16px;
}
.content {
    .p {
        line-height: 26px;
        text-indent: 2em;
    }
    p{
        line-height: 26px;
        text-indent: 2em;
    }
    li {
        margin-left: 3.5em;
        line-height: 26px;
        list-style: initial;
    }
}
.to-title {
    cursor: pointer;
    font-weight: 700;
    color: #666666;
    font-size: 18px;
    transition: all .1s;
    &:hover {
        color: rgba(234, 164, 0, 1);
    }
}
.next {
    margin: 62px 0;
    cursor: pointer;
    border-left: 3px solid rgba(215, 215, 215, 1);
    background-color: rgba(242, 242, 242, 1);
    height: 100px;
    position: relative;
    transition: all .1s;
    .align {
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        .to {
            color: rgb(170, 170, 170);
            line-height: 28px;
        }
    }
    &:hover {
        background-color: #0378e6;
        .to,.to-title {
            color: #fff !important;
        }
    }
}
.r-item {
    .r-date {
        color: #AAAAAA;
        font-size: 14px;
    }
    .r-text {
        font-size: 14px;
        color: #AAAAAA;
        margin-top: 6px;
        line-height: 24px;
    }
}
/deep/ .ant-divider-horizontal{
    margin: 12px 0;
}
.main {
    background: #d7d7d7;
    margin: 12px 0 16px 0;
}
</style>